import { useEffect, useState } from 'react'
import { TitleCogs } from './components/TitleCogs'
import ReactGA from 'react-ga4'
import axios from 'axios'
export const Products = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        axios
            .get(`https://www.nisamerica.com/api/game/legend-nayuta-boundless-trails`)
            .then((response) => {
                const data = response.data.game.retailerLinks.map((item) => {
                    return {
                        url: item.url,
                        store: item.store,
                        edition: item.edition,
                        platform: item.platform,
                        region: item.region,
                    }
                })
                console.log(response.data.game.retailerLinks)

                setData(data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])
    const FilterRegion = (region, platform) =>
        data.filter((item) => item.region === region && item.edition === 'Deluxe Edition' && item.platform === platform)
    return (
        <div id='products' className='py-20 bg-nayutagrey bg-products flex flex-col items-center'>
            <div className='mb-12'>
                <TitleCogs title='PRODUCTS' color='brightness-90' />
            </div>
            <div className='gap-6 relative w-full px-4  '>
                <h1 className='text-nayutadarkgrey/20 head -mt-8 lg:-mt-0'>PRODUCTS</h1>
                <div className='flex flex-col justify-center pb-20'>
                    <div className='z-30 bg-nayutabrown uppercase text-white font-normal tracking-wider py-2 pl-4 md:pl-8 mb-8 w-full md:max-w-[1000px] mx-auto '>
                        limited edition
                    </div>
                    <img
                        className='max-w-full mx-auto z-30'
                        src={`${process.env.PUBLIC_URL}/assets/nayuta-le-ps4.jpg`}
                        alt='Limited Edition glamshot'
                    />{' '}
                    <div className='flex flex-row justify-center gap-6 mt-8 z-30'>
                        <div>
                            {' '}
                            <button className='h-12 bg-nayutared px-4 md:px-24 text-white border-2 border-white shadow-xl'>
                                <a
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'retailer link',
                                            category: 'limited edition',
                                            label: 'limited edition ps4 NISA store',
                                        })
                                    }
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://store.nisamerica.com/the-legend-of-nayuta-boundless-trails-limited-edition-ps4'
                                >
                                    PlayStation&#174;4
                                </a>
                            </button>
                        </div>
                        <div>
                            {' '}
                            <button className='h-12 bg-nayutared text-white  px-4 md:px-24 border-2 border-white shadow-xl'>
                                <a
                                    onClick={() =>
                                        ReactGA.event({
                                            action: 'click',
                                            event: 'retailer link',
                                            category: 'limited edition',
                                            label: 'limited edition nsw NISA store',
                                        })
                                    }
                                    target='_blank'
                                    rel='noreferrer'
                                    href='https://store.nisamerica.com/the-legend-of-nayuta-boundless-trails-limited-edition'
                                >
                                    Nintendo Switch&trade;
                                </a>
                            </button>
                        </div>{' '}
                    </div>
                </div>
                <div className='flex flex-col justify-center py-20 '>
                    <div className='z-30 bg-nayutabrown uppercase text-white font-normal tracking-wider py-2 pl-4 md:pl-8 mb-8 w-full max-w-[1000px] mx-auto'>
                        Deluxe edition
                    </div>
                    <div className='mb-24 max-w-[1000px] mx-auto'>
                        <img
                            className='max-w-full mx-auto z-30'
                            src={`${process.env.PUBLIC_URL}/assets/nayuta-de-ps4.jpg`}
                            alt='Deluxe Edition glamshot'
                        />{' '}
                        <section className='text-center mx-auto mt-8'>
                            <h1 className='text-nayutadarkgrey font-bold text-sm'>North America</h1>
                            <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                {FilterRegion('NA', 'PlayStation®4').map((data, i) => (
                                    <div className='w-[46%] md:w-[24%]'>
                                        <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                            <a
                                                onClick={() =>
                                                    ReactGA.event({
                                                        action: 'click',
                                                        event: 'link',
                                                        category: 'Deluxe Edition',
                                                        label: `deluxe edition ${data.platform} ${data.store}`,
                                                    })
                                                }
                                                target='_blank'
                                                rel='noreferrer'
                                                href={data.url}
                                            >
                                                {data.store}
                                            </a>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </section>
                        {FilterRegion('EU', 'PlayStation®4').length > 0 && (
                            <section className='text-center max-w-[1000px] mx-auto mt-8'>
                                <h1 className='text-nayutadarkgrey font-bold text-sm'>Europe</h1>
                                <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                    {FilterRegion('EU', 'PlayStation®4').map((data, i) => (
                                        <div className='w-[46%] md:w-[24%]'>
                                            <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                                <a
                                                    onClick={() =>
                                                        ReactGA.event({
                                                            action: 'click',
                                                            event: 'link',
                                                            category: 'Deluxe Edition',
                                                            label: `deluxe edition ${data.platform} ${data.store}`,
                                                        })
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={data.url}
                                                >
                                                    {data.store}
                                                </a>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                        {FilterRegion('AZN', 'PlayStation®4').length > 0 && (
                            <section className='text-center max-w-[1000px] mx-auto mt-8'>
                                <h1 className='text-nayutadarkgrey font-bold text-sm'>AZN</h1>
                                <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                    {FilterRegion('AZN', 'PlayStation®4').map((data, i) => (
                                        <div className='w-[46%] md:w-[24%]'>
                                            <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                                <a
                                                    onClick={() =>
                                                        ReactGA.event({
                                                            action: 'click',
                                                            event: 'link',
                                                            category: 'Deluxe Edition',
                                                            label: `deluxe edition ${data.platform} ${data.store}`,
                                                        })
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={data.url}
                                                >
                                                    {data.store}
                                                </a>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                    <div className='max-w-[1000px] mx-auto'>
                        <img
                            className='max-w-full mx-auto z-30'
                            src={`${process.env.PUBLIC_URL}/assets/nayuta-de-nsw.jpg`}
                            alt='Deluxe Edition glamshot'
                        />{' '}
                        <section className='text-center max-w-[1000px] mx-auto mt-8'>
                            <h1 className='text-nayutadarkgrey font-bold text-sm'>North America</h1>
                            <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                {FilterRegion('NA', 'Nintendo Switch™').map((data, i) => (
                                    <div className='w-[46%] md:w-[24%]'>
                                        <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                            <a
                                                onClick={() =>
                                                    ReactGA.event({
                                                        action: 'click',
                                                        event: 'link',
                                                        category: 'Deluxe Edition',
                                                        label: `deluxe edition ${data.platform} ${data.store}`,
                                                    })
                                                }
                                                target='_blank'
                                                rel='noreferrer'
                                                href={data.url}
                                            >
                                                {data.store}
                                            </a>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </section>
                        {FilterRegion('EU', 'Nintendo Switch™').length > 0 && (
                            <section className='text-center max-w-[1000px] mx-auto mt-8'>
                                <h1 className='text-nayutadarkgrey font-bold text-sm'>Europe</h1>
                                <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                    {FilterRegion('EU', 'Nintendo Switch™').map((data, i) => (
                                        <div className='w-[46%] md:w-[24%]'>
                                            <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                                <a
                                                    onClick={() =>
                                                        ReactGA.event({
                                                            action: 'click',
                                                            event: 'link',
                                                            category: 'Deluxe Edition',
                                                            label: `deluxe edition ${data.platform} ${data.store}`,
                                                        })
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={data.url}
                                                >
                                                    {data.store}
                                                </a>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                        {FilterRegion('AZN', 'Nintendo Switch™').length > 0 && (
                            <section className='text-center max-w-[1000px] mx-auto mt-8'>
                                <h1 className='text-nayutadarkgrey font-bold text-sm'>AZN</h1>
                                <div className='flex flex-wrap justify-center gap-4 mx-auto mt-4'>
                                    {FilterRegion('AZN', 'Nintendo Switch™').map((data, i) => (
                                        <div className='w-[46%] md:w-[24%]'>
                                            <button className='w-full bg-nayutared h-12 border-2 border-white text-white'>
                                                <a
                                                    onClick={() =>
                                                        ReactGA.event({
                                                            action: 'click',
                                                            event: 'link',
                                                            category: 'Deluxe Edition',
                                                            label: `deluxe edition ${data.platform} ${data.store}`,
                                                        })
                                                    }
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    href={data.url}
                                                >
                                                    {data.store}
                                                </a>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                    </div>
                </div>
                <div className='flex flex-col justify-center py-20 max-w-[1000px] mx-auto'>
                    <div className='bg-nayutabrown uppercase text-white font-normal tracking-wider py-2 pl-4 md:pl-8 mb-8 w-full max-w-[1000px] mx-auto'>
                        Digital
                    </div>
                    <div className='steamcontainer mx-auto w-full md:w-4/5'>
                        <iframe
                            title='The Legend of Nayuta: Boundless Trails'
                            src='https://store.steampowered.com/widget/1668530/'
                            frameborder='0'
                            width='646'
                            height='190'
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    )
}
