import { useEffect, useRef, useState } from 'react'

import { useScrollPosition } from './components/utility/useScrollPosition'

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)
    const scrollYPosition = useScrollPosition()

    return (
        <header className={'md:block items-center top-0 z-40 uppercase tracking-wider sticky'}>
            <div className={`hidden md:block absolute w-full`}>
                <div className='relative ease-in-out transition transform duration-700 '>
                    <ul
                        className={`flex flex-row space-x-6 cursor-pointer items-center mt-0 font-bold ${
                            scrollYPosition > 1000
                                ? `px-6 py-4 bg-white justify-center border-b-0 border-nayutagrey shadow-xl text-nayutadarkgrey ease-in-out duration-1000 `
                                : 'px-12 py-8 ease-in-out headerblack duration-1000 justify-center text-white'
                        }`}
                    >
                        <li className='cursor-pointer '>
                            <a
                                end
                                href='#top'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Top
                            </a>
                        </li>
                        <li>
                            <a
                                href='#media'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Media
                            </a>
                        </li>
                        <li>
                            <a
                                href='#prologue'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Prologue
                            </a>
                        </li>{' '}
                        <li>
                            <a
                                href='#characters'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Characters
                            </a>
                        </li>
                        <li>
                            <a
                                href='#world'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                world
                            </a>
                        </li>{' '}
                        <li>
                            <a
                                href='#products'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Products
                            </a>
                        </li>
                        <li>
                            <a
                                href='#spec'
                                className={'hover:text-rt3eblue hover:duration-300 hover:transition-none ease-in-out'}
                            >
                                Spec
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* mobile navbar */}
            <div className='cursor-pointer'>
                <div className='blcok md:hidden absolute right-5 top-5'>
                    {navbarOpen ? (
                        ''
                    ) : (
                        <button className='delay-300 ease-in-out z-50' onClick={() => setNavbarOpen(!navbarOpen)}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-9 w-9  bg-nayutadarkgrey shadow-md'
                                fill='black'
                                viewBox='0 0 24 24'
                                stroke='white'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M4 6h16M4 12h16M4 18h16'
                                />
                            </svg>
                        </button>
                    )}
                </div>
                <div className='flex flex-row md:hidden sticky z-50'>
                    <div
                        className={`ease-in-out duration-500 py-20 top-0 left-0 w-full text-white bg-black/90 fixed md:hidden shadow-2xl h-screen ${
                            navbarOpen ? 'translate-y-0 translate-x-0' : '-translate-y-[1300px]'
                        }`}
                    >
                        <button className='delay-300 ease-in-out z-0' onClick={() => setNavbarOpen(!navbarOpen)}>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-6 w-6 absolute right-8 z-0 top-8 '
                                fill=''
                                viewBox='0 0 24 24'
                                stroke='white'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='M6 18L18 6M6 6l12 12'
                                />
                            </svg>
                        </button>{' '}
                        <ul className='flex flex-col justify-center items-center space-y-8 text-base'>
                            <li>
                                <a
                                    end
                                    href='/'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Top
                                </a>
                            </li>
                            <li>
                                <a
                                    href='#media'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Media
                                </a>
                            </li>{' '}
                            <li>
                                <a
                                    href='#prologue'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Prologue
                                </a>
                            </li>{' '}
                            <li>
                                <a
                                    href='#characters'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Characters
                                </a>
                            </li>
                            <li>
                                <a
                                    href='#world'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    World
                                </a>
                            </li>{' '}
                            <li>
                                <a
                                    href='#products'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Products
                                </a>
                            </li>{' '}
                            <li>
                                <a
                                    href='#spec'
                                    className={
                                        'hover:text-rt3eblue hover:duration-500 hover:transition-none ease-in-out'
                                    }
                                    onClick={() => {
                                        setNavbarOpen(false)
                                    }}
                                >
                                    Spec
                                </a>
                            </li>{' '}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Navbar
