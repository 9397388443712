import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, Thumbs, Autoplay, EffectFade } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'

export const IntroCarousel = () => {
    const ss = [...Array(4).keys()].map((i) => ++i)
    return (
        <Swiper
            grabCursor={false}
            navigation={false}
            effect={'fade'}
            pagination={true}
            loop={true}
            autoplay={{
                delay: 3000,
                disableOnInteraction: false,
            }}
            // autoplay={false}
            onSlideChange={(swiper) => console.log(swiper.realIndex)}
            modules={[Pagination, Navigation, Thumbs, Autoplay, EffectFade]}
            className=''
        >
            {ss.map((data) => (
                <SwiperSlide>
                    <img
                        key={data.id}
                        alt='screen shot of the legend of Nayuta'
                        src={`${process.env.PUBLIC_URL}/assets/ss/0${data}.webp`}
                        className=''
                    />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}
