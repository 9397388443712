
export const TitleCogs = ({ title, color }) => {
    return (
        <>
            <div className='relative text-center my-20 md:my-40 lg:mt-24 lg:mb-20 inline-block '>
                <h2 className='uppercase font-bold text-nayutadarkgrey text-md md:text-lg relative z-20 min-w-[150px] md:min-w-[220px]'>
                    {title}
                </h2>
                <img
                    className={`${color} w-3/5 md:w-4/5 mx-auto absolute right-0 md:-right-8 -top-16 md:-top-28 z-10 animate-spinslowreverse`}
                    src={`${process.env.PUBLIC_URL}/assets/cogs-01.svg`}
                    alt='cog'
                />{' '}
                <img
                    className={`${color} w-1/4 md:w-1/3 absolute left-8 md:left-0 -top-8 md:-top-16 z-10 animate-spinslowreverse`}
                    src={`${process.env.PUBLIC_URL}/assets/cogs-03.svg`}
                    alt='cog'
                />{' '}
                <img
                    className={`${color} w-1/3 md:w-1/2 absolute left-12 md:left-0 top-4 md:top-0 z-10 animate-spinslow`}
                    src={`${process.env.PUBLIC_URL}/assets/cogs-02.svg`}
                    alt='cog'
                />{' '}
            </div>
        </>
    )
}
