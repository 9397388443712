import { motion, useScroll, useTransform, useSpring } from 'framer-motion'
import { Footer } from './Footer'
import { Hero } from './Hero'
import { Intro } from './Intro'
import { Media } from './Media'
import { Products } from './Products'
import { Prologue } from './Prologue'
import { Spec } from './Spec'
import Navbar from './Navbar'
import { Characters } from './Characters'
import { World } from './World'

function App() {
    const { scrollYProgress } = useScroll()
    // const rotate =  useSpring(useTransform(scrollYProgress, [0, 1], [0, 180]))
    // const rotateReverse = useSpring(useTransform(scrollYProgress, [0, 1], [180, 0]))
    const rotate = useTransform(scrollYProgress, [0, 1], [0, 360])
    const rotateReverse = useTransform(scrollYProgress, [0, 1], [360, 0])

    console.log(scrollYProgress + 'progressed')
    return (
        <>
            <Navbar />
            <Hero />
            <Intro />
            <Media /> <Prologue />
            <Characters />
            <World />
            <Products />
            <Spec />
            <Footer />
            <div className='relative w-full h-full overflow-x-hidden overflow-y-auto '>
                <div id='fixed-frame-cogs-tl' className='frame-cogs'>
                    <div className='inner fv fixed tl '>
                        <motion.img
                            style={{ rotate: rotate }}
                            className='a cog'
                            src={`${process.env.PUBLIC_URL}/assets/a.png`}
                            alt='cog'
                        />{' '}
                        <img className='base cog' src={`${process.env.PUBLIC_URL}/assets/base.webp`} alt='logo' />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='d cog'
                            src={`${process.env.PUBLIC_URL}/assets/d.webp`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='e cog'
                            src={`${process.env.PUBLIC_URL}/assets/e.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='b cog'
                            src={`${process.env.PUBLIC_URL}/assets/b.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='c cog'
                            src={`${process.env.PUBLIC_URL}/assets/c.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='f cog'
                            src={`${process.env.PUBLIC_URL}/assets/f.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='g cog'
                            src={`${process.env.PUBLIC_URL}/assets/g.png`}
                            alt='cog'
                        />
                    </div>
                </div>{' '}
                <div id='fixed-frame-cogs-tr' className='hidden lg:block frame-cogs -mt-20'>
                    <div className='inner fv fixed tr'>
                        <motion.img
                            style={{ rotate: rotate }}
                            className='a cog'
                            src={`${process.env.PUBLIC_URL}/assets/a.png`}
                            alt='cog'
                        />{' '}
                        <img className='base cog' src={`${process.env.PUBLIC_URL}/assets/base.webp`} alt='cog' />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='d cog'
                            src={`${process.env.PUBLIC_URL}/assets/d.webp`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='e cog'
                            src={`${process.env.PUBLIC_URL}/assets/e.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='b cog'
                            src={`${process.env.PUBLIC_URL}/assets/b.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='c cog'
                            src={`${process.env.PUBLIC_URL}/assets/c.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='f cog'
                            src={`${process.env.PUBLIC_URL}/assets/f.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='g cog'
                            src={`${process.env.PUBLIC_URL}/assets/g.png`}
                            alt='cog'
                        />
                    </div>
                </div>{' '}
                <div id='fixed-frame-cogs-br' className='frame-cogs mb-20'>
                    <div className='inner fv fixed br'>
                        <motion.img
                            style={{ rotate: rotate }}
                            className='a cog'
                            src={`${process.env.PUBLIC_URL}/assets/a.png`}
                            alt='logo'
                        />{' '}
                        <img className='base cog' src={`${process.env.PUBLIC_URL}/assets/base.webp`} alt='cog' />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='d cog'
                            src={`${process.env.PUBLIC_URL}/assets/d.webp`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='e cog'
                            src={`${process.env.PUBLIC_URL}/assets/e.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='b cog'
                            src={`${process.env.PUBLIC_URL}/assets/b.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='c cog'
                            src={`${process.env.PUBLIC_URL}/assets/c.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotateReverse }}
                            className='f cog'
                            src={`${process.env.PUBLIC_URL}/assets/f.png`}
                            alt='cog'
                        />
                        <motion.img
                            style={{ rotate: rotate }}
                            className='g cog'
                            src={`${process.env.PUBLIC_URL}/assets/g.png`}
                            alt='cog'
                        />
                    </div>
                </div>{' '}
                <button className='fixed bottom-0 md:bottom-4 left-0 md:left-4 hover:brightness-105 hover:duration-300 z-40 bounce-slow'>
                    <a aria-label='Legend of heroes portal' target='_blank' rel='noreferrer' href='https://thelegendofheroes.com/'>
                        <img
                            className='w-[36vw] md:w-[32vw] lg:w-full max-w-full'
                            src={`${process.env.PUBLIC_URL}/assets/mishy.png`}
                            alt='logo'
                        />
                    </a>
                </button>
            </div>{' '}
        </>
    )
}

export default App
