import { TitleCogs } from './components/TitleCogs'

export const World = () => {
    return (
        <>
            <div id='world' className='flex flex-col justify-center items-center md:p-4 overflow-hidden'>
                <div className='mt-20 mb-12'>
                    <TitleCogs title='World' />
                </div>
                <div className='bg-heaven w-full'>
                    <div className='relative'>
                        <h1 className='text-white/20 head uppercase'>World</h1>
                        <div className='w-full relative text-right p-8 md:p-20'>
                            <h3 className='mb-2 md:mb-4 lg:mb-12 text-white text-xlg story-textshadow'>Lost Heaven</h3>
                            <div className='mt-12 text-sub md:text-sm lg:text-base tracking-wider text-white md:w-3/5 lg:w-2/5 ml-auto story-textshadow'>
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    A world of beautiful and fantastical scenery said to be visible in the Star
                                    Fragments.
                                </p>{' '}
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    With dynamic natural landscapes and never-before-seen flora and fauna, people have
                                    decided to call this world "Lost Heaven" out of admiration and longing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='max-w-[1200px] mx-auto flex flex-col md:flex-row my-20 md:my-40 md:gap-16 items-center px-4'>
                    <div className='w-full md:w-1/2'>
                        <h3 className='mb-2 md:mb-4 lg:mb-12 text-lg md:text-xlg'>Remnant Isle</h3>
                        <div className='text-sub md:text-sm'>
                            <p>The Sea of Ciencia features an archipelago of countless islands.</p>
                            <p>
                                In the middle of them is the peaceful Remnant Isle. For some reason, shooting stars and
                                ruins have been falling from the sky and landing around the island.
                            </p>
                            <p>
                                It is believed that Remnant Isle itself was created by an accumulation of this debris
                                over the years. After a shooting star falls, mysterious ores known as Star Fragments can
                                be found near the point of impact.
                            </p>
                            <p>
                                They can also be found along the coast, carried by waves and ocean currents, but nowhere
                                else in the Sea of Ciencia can they be found in such abundance as on Remnant Isle.
                            </p>
                        </div>{' '}
                    </div>
                    <div className='w-full md:w-1/2'>
                        <img
                            className='w-full h-auto mx-auto border-4 border-white shadow-xl'
                            src={`${process.env.PUBLIC_URL}/assets/nokosare.webp`}
                            alt='remnant island'
                        />{' '}
                    </div>
                </div>
            </div>
        </>
    )
}
