import { TitleCogs } from './components/TitleCogs'

export const Spec = () => {
    return (
        <div id='spec' className='flex flex-col justify-center items-center mb-20'>
            <div className='mt-12'>
                <TitleCogs title='SPEC' />
            </div>
            <div className='relative flex flex-row gap-4'>
                <div className='-ml-24 basis-1/3 hidden lg:block'>
                    <img
                        className='max-w-full'
                        src={`${process.env.PUBLIC_URL}/assets/bg2.webp`}
                        alt='creha key visual'
                    />{' '}
                </div>
                <div className='lg:basis-2/3 flex flex-col justify-center'>
                    <table className='mx-auto text-nayutabrown decoration-none table-auto border-separate [border-spacing:0_0.4rem] w-full px-4 xl:px-0 max-w-full md:max-w-[800px]'>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 md:px-16 text-sub font-normal text-white'>
                                Title
                            </th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>The Legend of Nayuta: Boundless Trails</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>Platform</th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>
                                PS4&trade;, Nintendo Switch&trade;, and PC
                            </td>
                        </tr>

                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>Genre</th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>Action RPG</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>Players</th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>1</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>Developer</th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>Nihon Falcom</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>
                                Text Language(s)
                            </th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>English</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>
                                Voice Language(s)
                            </th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>English / Japanese</td>
                        </tr>
                        <tr>
                            <th className='bg-nayutabrown md:py-6 p-2 text-sub font-normal text-white'>Release date</th>
                            <td className='text-left pl-4 md:pl-8 text-sub'>
                                US: September 19th 2023 <br />
                                EU: 22nd September 2023
                                <br />
                                ANZ: 22nd September 2023
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}
