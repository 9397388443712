import { TitleCogs } from './components/TitleCogs'

export const Characters = () => {
    return (
        <>
            <div id='characters' className='flex flex-col justify-center items-center md:p-4 overflow-hidden'>
                <div className='my-12'>
                    {' '}
                    <TitleCogs title='Characters' />{' '}
                </div>
            </div>
            <div className='w-full relative px-4'>
                <h1 className='text-gray-100 head hidden md:block'>CHARACTERS</h1>
                <div className='max-w-[1400px] mx-auto relative z-30'>
                    <div className='flex flex-wrap cursor-pointer gap-4 max-w-[1200px] mx-auto px-4'>
                        <div className='w-[47%] md:w-[23%] hover:bg-nayuta bg-white border-2 border-nayuta hover:text-white text-nayuta duration-500 relative'>
                            <a aria-label='Nayuta' href='#charnayuta'>
                                <p className='absolute top-1 left-2 text-sm'>Nayuta</p>
                                <img
                                    className=''
                                    src={`${process.env.PUBLIC_URL}/assets/characters/nayuta2.webp`}
                                    alt='Nayuta'
                                />{' '}
                            </a>
                        </div>{' '}
                        <div className='w-[47%] md:w-[23%] hover:bg-noi text-noi hover:text-white duration-500 relative'>
                            <a aria-label='Noi' href='#charnoi'>
                                {' '}
                                <p className='absolute top-1 left-2 text-sm'>Noi</p>
                                <img
                                    className='border-2 border-noi'
                                    src={`${process.env.PUBLIC_URL}/assets/characters/noi5.webp`}
                                    alt='Noi'
                                />{' '}
                            </a>
                        </div>{' '}
                        <div className='w-[47%] md:w-[23%] hover:bg-creha hover:text-white bg-white text-creha duration-300 relative'>
                            <a aria-label='Creha' href='#charcreha'>
                                <p className=' absolute top-1 left-2 text-sm'>Creha</p>
                                <img
                                    className='border-2 border-creha'
                                    src={`${process.env.PUBLIC_URL}/assets/characters/creha3.webp`}
                                    alt='Creha'
                                />{' '}
                            </a>
                        </div>{' '}
                        <div className='w-[47%]  md:w-[23%] hover:bg-signa hover:text-white bg-white text-signa duration-300 relative'>
                            <a aria-label='Cygna'  href='#charcygna'>
                                <p className='absolute top-1 left-2 text-sm'>Cygna</p>
                                <img
                                    className='border-2 border-signa'
                                    src={`${process.env.PUBLIC_URL}/assets/characters/signa3.webp`}
                                    alt='Cygna'
                                />{' '}
                            </a>
                        </div>
                    </div>
                    <div
                        id='charnayuta'
                        className='flex flex-col md:flex-row md:mt-32 md:gap-8 relative mx-auto md:px-2 py-4 max-w-1/2'
                    >
                        <div className='w-full md:w-1/2 relative text-center block '>
                            <img
                                className='h-auto mx-auto'
                                src={`${process.env.PUBLIC_URL}/assets/characters/nayuta.webp`}
                                alt='Nayuta'
                            />{' '}
                            <div className='char-bg right-0'>
                                <div className='inner'>
                                    <div className='relative h-full w-full filter'> </div>
                                    <div id='nayuta' className='absolute top-0 left-0 w-full h-full' />
                                </div>
                            </div>
                        </div>

                        <div className='w-full md:w-1/2 md:m-8 px-4'>
                            <h2 className='text-nayuta text-xlg font-thin mt-12'>Nayuta Herschel</h2>
                            <div className='text-sub md:text-sm mt-4 md:mt-12'>
                                {' '}
                                <p>The Protagonist.</p>
                                <p>
                                    Nayuta hails from Remnant Isle but attends an academy in the port town of Saint
                                    Elysée which rests beside the Sea of Ciencia.
                                </p>{' '}
                                <p>
                                    He's passionate about astronomy and uses a sextant and telescope left behind by his
                                    father.
                                </p>
                                <p>
                                    {' '}
                                    "Why do the ruins fall onto Remnant Isle? Is the earth truly flat?" Nayuta asks
                                    himself many such questions and is naturally drawn to the unknown.
                                </p>
                                <p>
                                    He is a logical thinker who makes his own conjectures. This makes him a good match
                                    to his childhood friend, Cygna, who relies only on intuition and physical prowess.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        id='charnoi'
                        className='flex flex-col-reverse md:flex-row mt-12 gap-8 relative mx-auto md:px-2 py-4 max-w-1/2 px-4'
                    >
                        <div className='w-full md:w-1/2 md:m-8'>
                            <h2 className='text-noi text-xlg font-thin mt-12 text-left'>Noi</h2>
                            <div className='text-sub md:text-sm mt-12 text-left'>
                                {' '}
                                <p>A tiny, floating girl that Nayuta meets.</p>
                                <p>She almost looks like she came out of a fairy tale.</p>
                                <p>
                                    She used to possess a mysterious object called the Master Gear, but a man appeared
                                    suddenly and stole it.
                                </p>{' '}
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 relative text-center block '>
                            <img
                                className='mx-auto'
                                src={`${process.env.PUBLIC_URL}/assets/characters/noi.webp`}
                                alt='Noi'
                            />{' '}
                            <div className='char-bg right-0 md:left-0'>
                                <div className='inner'>
                                    <div className='relative h-full w-full filter'> </div>
                                    <div id='noi' className='absolute top-0 left-0 w-full h-full' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id='charcreha'
                        className='flex flex-col md:flex-row mt-8 gap-8 relative mx-auto md:px-2 py-4 max-w-1/2 px-4'
                    >
                        <div className='w-full md:w-1/2 relative text-center block '>
                            <img
                                className='mx-auto'
                                src={`${process.env.PUBLIC_URL}/assets/characters/creha.webp`}
                                alt='Creha'
                            />{' '}
                            <div className='char-bg right-0'>
                                <div className='inner'>
                                    <div className='relative h-full w-full filter'> </div>
                                    <div id='creha' className='absolute top-0 left-0 w-full h-full' />
                                </div>
                            </div>
                        </div>

                        <div className='w-full md:w-1/2 md:m-8'>
                            <h2 className='text-creha text-xlg font-thin mt-12'>Creha</h2>
                            <div className='text-sub md:text-sm  mt-4 md:mt-12'>
                                {' '}
                                <p>A delicate girl who has been sleeping in Lost Heaven for a long time.</p>
                                <p>
                                    She awakes due to a certain event. She doesn't say much about herself, perhaps
                                    because her memory is incomplete.
                                </p>{' '}
                            </div>
                        </div>
                    </div>
                    <div
                        id='charcygna'
                        className='flex flex-col-reverse md:flex-row mt-8 gap-8 relative mx-auto md:px-2 py-4 max-w-1/2 px-4'
                    >
                        <div className='w-full md:w-1/2 md:m-8'>
                            <h2 className='text-signa text-xlg font-thin mt-12'>Cygna Alhazen</h2>
                            <div className='text-sub md:text-sm mt-4 md:mt-12'>
                                {' '}
                                <p>
                                    Nayuta's childhood friend, Cygna, works as a member of Saint Elysée's Vigilante
                                    Corps. He is a reliable older-brother figure and good friend to Nayuta.
                                </p>
                                <p>
                                    He became peerless with the sword through the tutelage of Orbus, able to
                                    single-handedly defeat the monsters that sometimes appear on the island, and is
                                    highly respected by those around him as a result.
                                </p>{' '}
                                <p>He and Nayuta opened a handyman business years ago to help the villagers.</p>
                            </div>
                        </div>
                        <div className='w-full md:w-1/2 relative text-center block '>
                            <img
                                className='w-auto h-auto mx-auto'
                                src={`${process.env.PUBLIC_URL}/assets/characters/signa.webp`}
                                alt='Cygna'
                            />{' '}
                            <div className='char-bg right-0 md:left-0'>
                                <div className='inner'>
                                    <div className='relative h-full w-full filter'> </div>
                                    <div id='signa' className='absolute top-0 left-0 w-full h-full' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{' '}
            </div>
        </>
    )
}
