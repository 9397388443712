import { useState } from 'react'
import ReactGA from 'react-ga4'
export const Footer = () => {
    const [email, setEmail] = useState('')
    return (
        <footer className='bg-gray-100 py-20 px-8 text-nayutadarkgrey'>
            <div className='container md:px-0 md:py-12  text-center max-w-[1400px] xl:max-w-3/5'>
                <div className='flex flex-col md:flex-row space-y-4 md:space-x-12 justify-center items-center py-20'>
                    <div className='flex w-full justify-center space-x-6 md:basis-2/6 mx-auto'>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS Twitter`,
                                    })
                                }}
                                href='https://twitter.com/nisamerica'
                                target='_blank'
                                rel='noreferrer'
                                data-label='Twitter'
                            >
                                <i className='fab fa-twitter fa-2x md:fa-5x btn'></i>
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS Facebook`,
                                    })
                                }}
                                href='https://www.facebook.com/NISAmericaInc/'
                                target='_blank'
                                rel='noreferrer'
                                data-label='Facebook'
                            >
                                <i className='fab fa-facebook-f fa-2x btn'></i>
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS Instagram`,
                                    })
                                }}
                                href='https://www.instagram.com/nisamerica/'
                                target='_blank'
                                rel='noreferrer'
                                data-label='Instagram'
                            >
                                <i className='fab fa-instagram fa-2x btn'></i>
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS YouTube`,
                                    })
                                }}
                                href='https://www.youtube.com/user/NISAmerica'
                                target='_blank'
                                rel='noreferrer'
                                data-label='YouTube'
                            >
                                <i className='fab fa-youtube fa-2x btn'></i>
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS Twitch`,
                                    })
                                }}
                                href='https://www.twitch.tv/nisamerica'
                                target='_blank'
                                rel='noreferrer'
                                data-label='Twitch'
                            >
                                <i className='fab fa-twitch fa-2x btn'></i>
                            </a>
                        </div>
                        <div>
                            <a
                                onClick={() => {
                                    ReactGA.event({
                                        action: 'click',
                                        event: 'link',
                                        category: 'Social Icons',
                                        label: `NIS Discord`,
                                    })
                                }}
                                href='https://discord.gg/nisa'
                                target='_blank'
                                rel='noreferrer'
                                data-label='Discord'
                            >
                                <i className='fab fa-discord fa-2x btn'></i>
                            </a>
                        </div>
                    </div>
                    <div className='mailchimp md:basis-4/6'>
                        <form
                            action='https://nisamerica.us11.list-manage.com/subscribe/post?u=e8fc423eff95099d1ffcc6009&amp;id=349f7d20f9'
                            method='post'
                            id='mc-embedded-subscribe-form'
                            name='mc-embedded-subscribe-form'
                            className='validate'
                            target='_blank'
                            noValidate=''
                        >
                            <div id='mc_embed_signup_scroll' className='mailchimp text-center'>
                                <div>
                                    <label htmlFor='mce-EMAIL' className='subscribe text-sm'>
                                        Subscribe to our mailing list to get the latest information!
                                    </label>
                                </div>
                                <div className='lg:px-40 xl:px-60'>
                                    <div className='flex items-stretch my-8 justify-center border-0 border-nayutabrown'>
                                        <input
                                            onClick={() => {
                                                ReactGA.event({
                                                    action: 'click',
                                                    event: 'link',
                                                    category: 'Social Icons',
                                                    label: `NIS Newsletter`,
                                                })
                                            }}
                                            className='bg-white text-sub w-full text-gray-800 px-4 py-2 border focus:outline-none focus:border-green-300'
                                            type='email'
                                            placeholder='Your Email'
                                            value={email}
                                            name='b_e8fc423eff95099d1ffcc6009_349f7d20f9'
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <button className='bg-nayutabrown text-sub tracking-widest text-white px-2 md:px-8 py-2 uppercase'>
                                            subscribe
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='max-w-[1000px] flex md:flex-nowrap flex-wrap justify-center space-y-4 md:space-y-0 space-x-2 lg:space-x-8 mx-auto items-center'>
                    <div className='basis-[44%]'>
                        <img
                            className='w-full md:max-w-[240px] mx-auto'
                            src={`${process.env.PUBLIC_URL}/assets/esrb-10.svg`}
                            alt='esrb rating'
                        />
                    </div>{' '}
                    <div className='basis-[44%]'>
                        <img
                            className='max-w-[260px] w-full mx-auto'
                            src={`${process.env.PUBLIC_URL}/assets/ps4-b.svg`}
                            alt='play station4'
                        />
                    </div>{' '}
                    <div className='basis-[44%]'>
                        <img
                            className='max-w-[240px] w-full mx-auto'
                            src={`${process.env.PUBLIC_URL}/assets/nsw-b.png`}
                            alt='nintendo switch'
                        />
                    </div>
                    <div className=''>
                        <img
                            className='max-w-[240px] mx-auto'
                            src={`${process.env.PUBLIC_URL}/assets/steam-b.png`}
                            alt='steam'
                        />
                    </div>{' '}
                </div>
                <div className='flex flex-row justify-center space-x-0 md:space-x-4 items-center mt-8'>
                    <div className='basis-1/2 lg:basis-1/5'>
                        <img
                            className='lg:max-w-full max-w-[280px]'
                            src={`${process.env.PUBLIC_URL}/assets/logo-falcom.svg`}
                            alt='falcom'
                        />
                    </div>

                    <div className='basis-1/2 lg:basis-1/5 max-w-[240px]'>
                        <img className='md:max-w-full' src={`${process.env.PUBLIC_URL}/assets/nis-b.png`} alt='nis' />
                    </div>
                </div>
                <div className='py-8'>
                    <div className='text-sub font-normal border-t-0 border-nayutadarkgrey'>
                        <p className='pt-12'>
                            © Nihon Falcom Corporation. All Rights Reserved. Licensed to and published by NIS America,
                            Inc.
                        </p>
                        <p>
                            ©2023 Sony Interactive Entertainment LLC. "<span className='font-sie'>1</span>",
                            "PlayStation", "<span className='font-sie'>5</span>", "PS5”, "
                            <span className='font-sie'>4</span>", "PS4", "PSP", "<span className='font-sie'>0</span>"
                            and "Play Has No Limits" are registered trademarks or trademarks of Sony Interactive
                            Entertainment Inc.
                        </p>

                        <p>Nintendo Switch is a trademark of Nintendo.</p>
                        <p>
                            ©2023 Valve Corporation. Steam and the Steam logo are trademarks and/or registered
                            trademarks of Valve Corporation in the U.S. and/or other countries. All rights reserved.
                        </p>
                        <p>
                            The rating icon is a trademark of the Entertainment Software Association. All other
                            trademarks are properties of their respective owners.
                        </p>
                    </div>{' '}
                </div>
            </div>
        </footer>
    )
}