import { TitleCogs } from './components/TitleCogs'

export const Prologue = () => {
    return (
        <>
            <div id='prologue' className='flex flex-col justify-center items-center md:p-4 overflow-hidden'>
                <TitleCogs title='PROLOGUE' />
                <div className='bg-prologue w-full md:mt-20'>
                    <div className='relative'>
                        <h1 className='text-white/20 head'>PROLOGUE</h1>
                        <div className='w-full relative flex justify-center py-40 px-4'>
                            <div className='text-sub md:text-sm lg:text-base tracking-wider text-white text-center lg:w-1/2 items-center story-textshadow'>
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    Nayuta spends his days gazing up at the stars above his island home and wondering
                                    what lies beyond the horizon.
                                </p>
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    Though people claim the sea they live on is flat and finite, Nayuta knows there must
                                    be more out there, just waiting to be discovered.
                                </p>{' '}
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    Occasionally, ruins and stars will fall from the sky over Nayuta's home, offering
                                    what seem to be glimpses of other worlds.
                                </p>
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    While exploring one such ruin, he and his friend save a small, fairy-like girl by
                                    the name of Noi. She tells Nayuta that something very important has been stolen from
                                    her and asks for his help in retrieving it.
                                </p>
                                <p className='mb-2 md:mb-4 lg:mb-12'>
                                    It's with this that Nayuta's journey begins—one which will take him far beyond the
                                    confines of his island to experience new worlds and extraordinary discoveries!
                                </p>
                            </div>
                        </div>{' '}
                    </div>
                </div>{' '}
            </div>
        </>
    )
}
