import { TrailerCarousel } from "./components/carousel/TrailerCarousel"

export const Media = () => {
    return (
        <div id='media' className='relative z-10 mt-40 pb-40'>
            {/* <div className='video-wrapper mx-auto border-6 border-white shadow-xl my-8 md:my-40 relative z-30'>
                <div className='video-container'>
                    <iframe
                        width='560'
                        height='315'
                        src='https://www.youtube.com/embed/43GkstX9yew'
                        title='YouTube video player'
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                        allowfullscreen
                    ></iframe>
                </div>{' '}
            </div>{' '} */}
            <TrailerCarousel />
        </div>
    )
}
