import React, { useState } from 'react'
import ReactPlayer from 'react-player'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'

export const TrailerCarousel = () => {
    const [isPlaying, setIsPlaying] = useState(null)
    const trailers = [
        {
            id: 1,
            src: 'https://www.youtube.com/embed/43GkstX9yew',
        },
        {
            id: 2,
            src: 'https://youtu.be/RLbXBI4qvWI',
        },
    ]

    return (
        <Swiper
            slidesPerView={1.2}
            //    grabCursor={false}
            loop={false}
            pagination={false}
            onSlideChange={() => {
                setIsPlaying(null)
            }}
            autoplay={false}
            watchSlidesProgress={true}
            centeredSlides={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className='mySwiper max-w-[1200px]'
            grabCurs={true}
            breakpoints={{
                320: {
                    slidesPerView: 1.1,
                },
                480: {
                    slidesPerView: 1.3,
                },
                640: {
                    slidesPerView: 1.5,
                },
            }}
        >
            {trailers.map((data) => (
                <>
                    <SwiperSlide>
                        <div className='player-wrapper' />
                        <ReactPlayer
                            key={data.id}
                            url={data.src}
                            controls={true}
                            onPlay={() => {
                                setIsPlaying(data.id)
                            }}
                            width='100%'
                            height='100%'
                            playing={isPlaying === data.id}
                            config={{
                                youtube: {
                                    playerVars: { showinfo: 1 },
                                },
                            }}
                            className='react-player bg-black'
                        />
                    </SwiperSlide>
                </>
            ))}
        </Swiper>
    )
}
