import { IntroCarousel } from "./components/carousel/IntroCarousel"

export const Intro = () => {
    return (
        <div className='items-center  pb-28 md:my-20 px-4 md:px-8'>
            <div className='text-center pt-20'>
                <h3 className='text-base md:text-lg font-bold tracking-widest'>Discovery awaits beyond the horizon!</h3>
                <h4 className='text-sm mt-12'>
                    After releasing in Japan as <span className='italic'>Nayuta no Kiseki</span> in 2012,
                    <br /> this action-RPG spinoff of the <span className='italic'>Trails</span> series is finally
                    coming to the west as <span className='italic'>The Legend of Nayuta: Boundless Trails!</span>
                </h4>
            </div>
            <div className='max-w-[1400px] mx-auto mt-32 '>
                <div className='flex flex-wrap items-center'>
                    <div className='w-full md:w-1/2 self-end pb-12 z-10'>
                        <img className='cog-new pb-2' src={`${process.env.PUBLIC_URL}/assets/cog-new.png`} alt='logo' />

                        <h3 className='text-md font-bold'>
                            Features enhanced graphics for character and event scenes!
                        </h3>
                        <p className='mt-8 text-sub md:text-sm'>
                            New illustrations have been added for each character on Remnant Isle, where the game is set.
                            Graphic elements and effects have also been significantly enhanced, with new illustrations
                            added for event scenes.
                        </p>
                    </div>
                    <div className='w-1/2 hidden md:block'>
                        <img
                            className='mx-auto w-full scale-105'
                            src={`${process.env.PUBLIC_URL}/assets/intro/sketch_01.webp`}
                            alt='sketch of past scene'
                        />
                    </div>
                </div>{' '}
                <div className='relative md:h-[400px] md:mt-12'>
                    <img
                        className='w-full block md:hidden'
                        src={`${process.env.PUBLIC_URL}/assets/intro/event-past.webp`}
                        alt='sketch of past scene'
                    />
                    <div className='absolute left-0 hidden md:block'>
                        <img
                            className='w-1/2 md:w-full  max-w-[430px] -rotate-3 border-8 border-white shadow-xl'
                            src={`${process.env.PUBLIC_URL}/assets/intro/event.webp`}
                            alt='sketch of a scene with high quality graphic'
                        />
                    </div>{' '}
                    <div className='absolute top-20 left-[400px] z-30 hidden md:block'>
                        <img
                            className='w-1/2 md:w-full max-w-[140px]'
                            src={`${process.env.PUBLIC_URL}/assets/intro/arrow.png`}
                            alt='arrow decoration'
                        />{' '}
                    </div>{' '}
                    <div className='absolute z-20 left-[400px] top-32 hidden md:block'>
                        <img
                            className='w-full max-w-[400px] mx-auto'
                            src={`${process.env.PUBLIC_URL}/assets/intro/past.webp`}
                            alt='past scene'
                        />
                    </div>
                </div>
            </div>{' '}
            <div className='max-w-[1400px] mx-auto md:mt-32 '>
                <div className='flex flex-wrap items-end'>
                    <div className='w-full md:w-1/2 hidden md:block'>
                        <img
                            className='mx-auto w-full scale-105'
                            src={`${process.env.PUBLIC_URL}/assets/intro/sketch_02.webp`}
                            alt='skech of past scene'
                        />
                    </div>
                    <div className='w-full md:w-1/2 z-10'>
                        {' '}
                        <img className='cog-new pb-2' src={`${process.env.PUBLIC_URL}/assets/cog-new.png`} alt='new' />
                        <h3 className='text-md font-bold'>Supports 60 FPS at HD with greater audio quality</h3>
                        <p className='mt-8 text-sub md:text-sm'>
                            The frame rate has been boosted to 60 FPS on PS4&trade;. The original PSP® version has been
                            upgraded to include HD quality visuals for more vivid graphical expressions, while the
                            background music and various sound effects have also been adjusted to achieve higher audio
                            quality.
                        </p>
                    </div>
                </div>{' '}
                <div className='flex flex-row justify-end mt-12'>
                    <div className='w-full md:w-1/2'>
                        <IntroCarousel />
                    </div>
                </div>
            </div>
        </div>
    )
}
