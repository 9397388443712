export const Hero = () => {
    return (
        <div
            id='top'
            className='h-screen bg-main flex flex-col justify-end mb-0 pb-8 lg:pb-0 lg:justify-center md:items-start z-0'
        >
            <div className='flex flex-col items-center'>
                <div className='mx-auto w-4/5 lg:w-[50vw] max-w-[1000px]'>
                    <img
                        className=' '
                        src={`${process.env.PUBLIC_URL}/assets/nayuta-logo.webp`}
                        alt='legend of Nayuta logo'
                    />
                </div>
                <div className='text-center'>
                    <p className='text-gray-700 font-bold tracking-wider text-sm md:text-base text-shadow'>
                        US: September 19th 2023
                        <br /> ANZ: 22nd September 2023 <br />
                        <span className='whitespace-nowrap'>EU: 22nd September 2023</span>
                    </p>
                    <p className='text-gray-700 font-bold tracking-wider text-base md:text-md text-shadow'></p>
                    <p className='text-nayutadarkgrey font-bold tracking-wider text-base md:text-md text-shadow'></p>
                </div>{' '}
                <div>
                    {' '}
                    <button className='text-sm tracking-wider h-12 bg-nayutared text-white px-20 border-2 border-white shadow-xl my-6 lg:my-12 hover:scale-105 hover:duration-300'>
                        <a aria-label='Buy Now' href='#products'>
                            BUY NOW
                        </a>
                    </button>
                </div>{' '}
            </div>
            <div></div>
        </div>
    )
}
